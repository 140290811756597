<template>
  <div>
    <v-container class="indigo lighten-5 my-5">
      <v-card class="white pa-3">
        <h1 class="text-center subtitle-4 black--text">
          الزبائن الذين يصادف عيد ميلادهم اليوم
        </h1>
        <v-row class="mt-5"> </v-row>
        <v-row>
          <v-col cols="12">
            <v-data-table
              :loading="table.loading"
              loading-text="جاري التحميل ... الرجاء الانتظار"
              :headers="table.headers"
              :items="table.teacherData"
              :search="table.search"
              :items-per-page="10"
              item-key="customer_id"
              class="elevation-1"
              :footer-props="{
                showFirstLastPage: true,
              }"
            >
              <template slot="item.customer_id" slot-scope="props">
                {{ props.index + 1 }}
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </div>
</template>

<script>
export default {
  data () {
    return {
      table: {
        search: null,
        totalTeacherData: 0,
        teacherData: [],
        loading: true,
        showImageDailog: false,
        imageUrlForShow: null,

        headers: [
          {
            text: '#',
            align: 'start',
            width: '5%',
            sortable: false,
            value: 'customer_id',
          },
          {
            text: 'الاسم',
            sortable: false,
            value: 'customer_name',
          },
          {
            text: 'الهاتف',
            sortable: false,
            value: 'customer_mobile',
          },
        ],
      },
    }
  },

  async mounted () {
    this.getDataAxios()
  },
  methods: {
    getDataAxios () {
      this.axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
      this.axios
        .get('/customerBirthdayToday')
        .then(Response => {
          if (Response.data.status === 401) {
            this.$store.dispatch('submitLogout')
          } else {
            this.table.loading = false
            this.table.teacherData = Response.data.results
          }
        })
        .catch(error => {
          this.table.loading = false
          console.log('error', error)
        })
    },
  },
}
</script>

<style scoped>
a {
  text-decoration: none;
}
.search-btn {
  width: 49px;
  height: 40px;
  padding: 10px;
  background: #2196f3;
  font-size: 17px;
  border: 1px solid grey;
  border-right: none;
  cursor: pointer;
  line-height: 100%;
  border-radius: 7px;
}
table.v-table tbody td {
  color: black;
}
</style>
